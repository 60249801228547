export default [
    {
        id: "sound_message",
        name: "Sound messages",
        text: "Recording and playing sound messages to radio units."
    },
    {
        id: "dsc",
        name: "DSC messages (legacy)",
        text: "Distress calls for Maritimes Offices."
    },
    {
        id: "gps",
        name: "GPS",
        text: "Functionalities for tracking radio stations."
    },
    {
        id: "sms",
        name: "SMS",
        text: "Accept and show SMS messages to user interface."
    },
    {
        id: "logs",
        name: "Logs",
        text: "Show call (voice) logs."
    },
    {
        id: "alarm_logs",
        name: "Show alarm logs",
        text: "Show alarm logs."
    },
    {
        id: "logs_with_map",
        name: "Logs with map",
        text: "Show maps in logs where it is possible."
    },
    {
        id: "telemetry",
        name: "Telemetry",
        text: "Telemetry functionalities."
    },
    {
        id: "radio",
        name: "Radio",
        text: "Radio stations will be used with the SITRAC Voice."
    },
    {
        id: "radio_users",
        name: "Radio Users",
        text: "SITRAC Voice will implement radio users in the system. Job ticketing ..."
    },
    {
        id: "radio_groups",
        name: "Radio Groups",
        text: "SITRAC Voice will use Radio Groups."
    },
    {
        id: "clock",
        name: "Show live clock",
        text: "Show live clock in SITRAC Voice."
    },
    {
        id: "change_cones",
        name: "Change cones",
        text: "Changing cones will be enabled in the system."
    },
    {
        id: "patching",
        name: "Patching",
        text: "Patching will be enabled."
    },
    {
        id: "more_rrtu_in_channel_groups",
        name: "More RRTU in channel groups",
        text: "One channel group will have multiple RRTU's"
    },
    {
        id: "namespace",
        name: "Namespace",
        text: "Use of namespace at radio channels and zones."
    },
    {
        id: "map",
        name: "Maps",
        text: "Use of maps."
    },
    {
        id: "extended_map",
        name: "Extended map",
        text: "Open map in new tab."
    },
    {
        id: "additional_info_for_channel",
        name: "Additional info for channel",
        text: "Above channel group there will be additional information about current channel selected."
    },
    {
        id: "remote_monitor_visible_on_radio_units",
        name: "Remote monitoring visible on radio units",
        text: "Remote monitoring will be enabled."
    },
    {
        id: "is_touch_screen",
        name: "Is touch screen?",
        text: "Will users have touch screen monitors?"
    },
    {
        id: "remote_kill",
        name: "Remote kill",
        text: "Kill radio station remotely."
    },
    {
        id: "test_rtc",
        name: "Testing voice without HADES",
        text: "Can be changed, during production."
    },
    {
        id: "telephone",
        name: "SIP telephone",
        text: "SIP telephone."
    },
    {
        id: "repeater",
        name: "Radio repeater",
        text: "Working with radio repeater system."
    }
]