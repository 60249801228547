import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { UserinfoService } from './userinfo.service';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class UserloadService {

  constructor(
    private permissionsService: NgxPermissionsService,
    private cookieService: CookieService,
    private userinfoService: UserinfoService
  ) { }

  load() {

    if (this.cookieService.get('jwtToken')) {

      return new Promise((resolve, reject) => {

        /*this.userinfoService.setLoggedIn(true);

        resolve(false);*/


        this.userinfoService.getUserInfo().subscribe((data: any) => {

          if(data && data.success) {

            let user = data.data;
            
            if (user.role == 0) {
              // admin          
              this.permissionsService.addPermission('ADMIN');
            }
            else if (user.role == 1) {
              // manager - account owner
              this.permissionsService.addPermission('MANAGER');
            }
            else {
              // employee
              this.permissionsService.addPermission('EMPLOYEE');
            }
    
            resolve(true)

          }
          else {
            resolve(false)
          }
  
        }, err => {         
          if(err.status != 200) {  
            
            resolve(false)

          }
        })

      });

    }
    else {

      this.userinfoService.setLoggedIn(false);
      
      return false;
    }

  }
  
}
