import { Component, OnInit, Inject, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';

import { MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { CustomerService } from '../../../services/customer.service';

@Component({
  selector: 'app-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss']
})
export class NewCustomerComponent implements OnInit {

  @Input() internal: boolean;
  @Output() added = new EventEmitter<boolean>();

  mainForm: FormGroup;
  submitted = false;
  loading: boolean = true;

  hide_vat: Boolean = false;
  form_error = false;
  update_customer: Boolean;

  constructor(
    private _dialogRef: MatDialogRef<NewCustomerComponent>,
    private fb: FormBuilder, 
    private customerService: CustomerService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  get f() { return this.mainForm.controls; }

  ngOnInit() {

    if(this.data && this.data.id) {

      this.customerService.getCustomer(this.data.id).subscribe((result: any) => {
        
        this.loading = false;

        if(result.success) {

          this.update_customer = true;
          this.createForm(result.data);
          
        }
        else {
          this.createForm();

          this._snackBar.open('Customer is not found in the database', '', {
            duration: 2000,
          });
        }
  
      }, err => {

        this.loading = false;

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error on the server', '', {
            duration: 2000,
          });
        }
      });

    } 
    else {
      this.loading = false;
      this.createForm();
    }   

  }

  createForm(existing_data?) {
    this.mainForm = this.fb.group({
      company_name: [(existing_data) ? existing_data.company_name : '', Validators.required],
      contact_name: [(existing_data) ? existing_data.contact_name + '' : '', Validators.required],
      contact_email: [(existing_data) ? existing_data.contact_email : '', Validators.required],
      street: [(existing_data) ? existing_data.street : '', Validators.required],
      city: [(existing_data) ? existing_data.city : '', Validators.required],
      country: [(existing_data) ? existing_data.country : '', Validators.required]
    });
  }

  onSelectVatChange(event) {
    
    if(event && event.value) {    
      if(event.value == 'false') {
        
        this.hide_vat = true;

        if (this.mainForm.contains('vat_id')) {
          this.mainForm.removeControl('vat_id');        
        }

      }
      else {

        this.hide_vat = false;

        if (!this.mainForm.contains('vat_id')) {          
          this.mainForm.addControl('vat_id', new FormControl('', Validators.required));
        }

      }

    }
  }

  onCancel() {

    if(!this.internal) {
      this._dialogRef.close();
    }
    else {
      this.added.emit(true);
    }

  }

  onSubmit() {
    this.submitted = true;

    this.mainForm.updateValueAndValidity();

    if (this.mainForm.status != "INVALID") {

      let action = null;

      if(this.update_customer) {
        action = this.customerService.updateNewCustomer(this.data.id, this.mainForm.value);
      }
      else {
        action = this.customerService.saveNewCustomer(this.mainForm.value);
      }

      action.subscribe((result: any) => {   

        if(result.success) {

          let data = this.mainForm.value;
          data['_id'] = result.id;
          
          if(!this.internal) {
            this._dialogRef.close(this.mainForm.value);
          }
          else {
            this.added.emit(true);
          }

          // snackbar
          this._snackBar.open( (this.update_customer) ? 'Customer updated' : 'Customer added', '', {
            duration: 2000,
          });
        }
  
      }, err => {
        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error on the server', '', {
            duration: 2000,
          });
        }
      })

    }
    else {
      this.form_error = true;
    }
  }

}
