import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from '../services/http-options.service';

import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  private webUrl = config.api_url;

  constructor(
    private _http: HttpClient,
    private _httpOptionsService: HttpOptionsService
  ) { }

  saveLicense(invoiceInfo) {
    return this._http.post(this.webUrl + '/api/license', invoiceInfo, this._httpOptionsService.getHeader())
  }

  getLicense(id) {
    return this._http.get(this.webUrl + '/api/license/' + id, this._httpOptionsService.getHeader())
  }

  getLicenses(from, to, index?, size?, filter?) {

    // Start building the query URL with required parameters
    let url_query = `?from=${moment(from).unix()}&to=${moment(to).unix()}`;

    // Append optional pagination parameters if provided
    if (index != null && size != null) {
      url_query += `&index=${index}&size=${size}`;
    }

    // Append optional filter parameter if provided
    if (filter) {
      url_query += `&filter=${encodeURIComponent(filter)}`; // Encode filter for safe URL
    }

    // Make the HTTP GET request with the constructed URL
    return this._http.get(this.webUrl + '/api/license' + url_query, this._httpOptionsService.getHeader());

  }

  deleteLicense(id) {
    return this._http.delete(this.webUrl + '/api/license/' + id, this._httpOptionsService.getHeader())
  }
}
