import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatDialogRef } from '@angular/material';

import { LicenseService } from '../../../services/license.service'

import functionalities from '../../../utils/sitrac-functionalities';
import { BrowseCustomersComponent } from '../../customers/browse-customers/browse-customers.component';


@Component({
  selector: 'app-add-license',
  templateUrl: './add-license.component.html',
  styleUrls: ['./add-license.component.scss']
})
export class AddLicenseComponent implements OnInit {

  mainForm: FormGroup;
  submitted = false;
  all_checked = false;

  available_funcs = [];
  limit_license_hidden = true;

  selected_customer = [];
  browseCustomerDialogRef: MatDialogRef<BrowseCustomersComponent>;

  constructor(
    private _fb: FormBuilder,
    private _dialog: MatDialog,
    private _licenseService: LicenseService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {

    let lang_json_structure_helper = [];

    for (let func of functionalities) {
      lang_json_structure_helper.push({
        name: func.name,
        id: func.id,
        text: func.text,
        checked: false
      });
    }

    this.available_funcs = lang_json_structure_helper;

  }

  ngOnInit() {
    this.createForm();
  }

  createForm(existing_data?) {

    this.mainForm = this._fb.group({
      computer_id: [(existing_data) ? existing_data.computer_id : '', Validators.required],
      limit_license: [(existing_data) ? existing_data.limit_license + '' : 'false', Validators.required],
      expiry: [(existing_data) ? existing_data.expiry : ''],
      customer_id: [(existing_data) ? existing_data.customer_id : '', Validators.required],
      license_data: this._fb.array((existing_data && existing_data.license_data) ? existing_data.license_data : []),
      demo: [(existing_data) ? existing_data.demo : 'false', Validators.required],
    });

    if (existing_data) {

      if (existing_data.limit_license) {

        if (this.mainForm.contains('expiry')) {
          this.mainForm.removeControl('expiry');

          this.mainForm.addControl('expiry', new FormControl('', Validators.required));
        }

      }

    }

  }

  onLimit(event) {
    this.limit_license_hidden = (event.value == 'true') ? false : true;

    if (event && event.value) {
      if (event.value == 'false') {

        if (this.mainForm.contains('expiry')) {
          this.mainForm.removeControl('expiry');
        }

      }
      else {

        if (!this.mainForm.contains('expiry')) {
          this.mainForm.addControl('expiry', new FormControl('', Validators.required));
        }

      }
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      let prepare_license_date = [];

      for (let func of functionalities) {

        let found = false;

        for (let i = 0; i < this.mainForm.value.license_data.length; i++) {
          if (func.id == this.mainForm.value.license_data[i].id) {
            found = true;
          }
        }

        if (found) {
          prepare_license_date.push({
            id: func.id,
            value: 1
          });
        }
        else {
          prepare_license_date.push({
            id: func.id,
            value: 0
          });
        }

      }

      let data = this.mainForm.value;
      data.license_specs = prepare_license_date;


      console.log(data);

      this._licenseService.saveLicense(data).subscribe((result: any) => {

        if (result.success) {

          // preusmeritev na stran računa

          this._router.navigate(['view/license/' + result.license_id]);


          // snackbar
          this._snackBar.open('License added', '', {
            duration: 2000,
          });
        }
        else {
          this._snackBar.open('Error', result.message, {
            duration: 2000,
          });
        }

      }, err => {
        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error on the server', '', {
            duration: 2000,
          });
        }
      });

    }
    else {
      this._snackBar.open('Please fill all the form', '', {
        duration: 2000,
      });
    }
  }

  newCustomer() {
    this.browseCustomerDialogRef = this._dialog.open(
      BrowseCustomersComponent,
      {
        width: '650px',
      }
    );

    this.browseCustomerDialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.selected_customer = [];

        this.selected_customer.push(result);

        this.mainForm.patchValue({
          customer_id: result._id
        });

        console.log(result);

      }

    });
  }

  onFunctionSelect(event) {

    const func_list = <FormArray>this.mainForm.get('license_data') as FormArray;

    event.source.value.checked = event.checked;

    if (event.checked) {
      func_list.push(new FormControl(event.source.value))
    } else {
      const i = func_list.controls.findIndex(x => x.value === event.source.value);
      func_list.removeAt(i);
    }

    // console.log(this.mainForm.get('license_data').value);

  }

  selectAll(event) {
    const func_list = <FormArray>this.mainForm.get('license_data') as FormArray;

    if (event.checked) {
      for (let func of this.available_funcs) {
        func.checked = true;
        func_list.push(new FormControl(func));
      }
    } else {
      for (let func of this.available_funcs) {
        func.checked = false;
        func_list.clear();
      }
    }
  }

}
