import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from '../services/http-options.service';

import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private webUrl = config.api_url; 

  constructor(
    private http: HttpClient,
    private httpOptionsService: HttpOptionsService
  ) { }

  saveNewCustomer(customerInfo) {
    return this.http.post(this.webUrl + '/api/customer', customerInfo, this.httpOptionsService.getHeader())
  }

  updateNewCustomer(customer_id, customerData) {
    return this.http.put(this.webUrl + '/api/customer/' + customer_id, customerData, this.httpOptionsService.getHeader())    
  }

  getCustomer(id) {
    return this.http.get(this.webUrl + '/api/customer/' + id, this.httpOptionsService.getHeader())
  }

  getCustomers(index?, size?, filter?) {

    let url_query = '';

    if(index != null && size != null) {
      url_query = '?index=' + index + '&size=' + size;

      if(filter) {
        url_query += '&filter=' + filter;
      }
    }
    else {
      if(filter) {
        url_query += '?filter=' + filter;
      }
    }

    return this.http.get(this.webUrl + '/api/customer' + url_query, this.httpOptionsService.getHeader())
  }

  deleteCustomer(id) {
    return this.http.delete(this.webUrl + '/api/customer/' + id, this.httpOptionsService.getHeader()) 
  }
}
