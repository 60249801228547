import { Component, OnInit } from '@angular/core';
import { SidebarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  opened = false;

  constructor(
    private _sidebarService: SidebarService
  ) { }

  ngOnInit() {

    this._sidebarService.sidebar_status$.subscribe(
      (data:any) => {
        if(data != null) {
          this.opened = data.status;
        }
      }
    )

  }

  toggleSidebar() {
    this._sidebarService.toggleSidebar();
  }

}
