import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { LicenseService } from 'src/app/services/license.service';

import functionalities from '../../../utils/sitrac-functionalities';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-view-license',
  templateUrl: './view-license.component.html',
  styleUrls: ['./view-license.component.scss']
})
export class ViewLicenseComponent implements OnInit {

  loading: Boolean = true;
  license_id: String;
  license = {};

  available_funcs = [];

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _licenseService: LicenseService
  ) {

    let lang_json_structure_helper = [];

    for (let func of functionalities) {
      lang_json_structure_helper.push({
        name: func.name,
        id: func.id,
        text: func.text,
        checked: false
      });
    }

    this.available_funcs = lang_json_structure_helper;

  }

  ngOnInit() {
    this.license_id = this._route.snapshot.paramMap.get('id');

    this._licenseService.getLicense(this.license_id).subscribe((result: any) => {

      if (result.success) {

        this.license = result.data;
        this.loading = false;

        for (let func of this.available_funcs) {

          for (let input of result.data.license_data) {

            if (func.id == input.id) {

              func.checked = true;

            }

          }

        }

      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Server error', '', {
          duration: 2000,
        });
      }
    });
  }

  deleteLicense(id) {


    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: {
          text: '... that you want to delete this license?'
        }
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {

      if (result) {

        this._licenseService.deleteLicense(id).subscribe((result: any) => {

          if (result.success) {

            this._router.navigate(['home']);

            this._snackBar.open('License was deleted', '', {
              duration: 2000,
            });

          }

        }, err => {
          if (err.status != 200) {
            this._snackBar.open('Error on the server', '', {
              duration: 2000,
            });
          }
        })

      }
    });

  }

  async copyToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      this._snackBar.open('License copied to clipboard', '', {
        duration: 2000,
      });

    } catch (err) {
      console.error("Failed to copy license:", err);

      this._snackBar.open('Failed to copy license', '', {
        duration: 2000,
      });
    }
  }


}
