import { Component, OnInit } from '@angular/core';
import { UserinfoService } from './services/userinfo.service';
import { SidebarService } from './services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'license-app';

  opened = false;
  loggedin = false;

  constructor(
    private _sidebarService: SidebarService,
    private userinfoService: UserinfoService
  ) { }

  ngOnInit() {

    this.userinfoService.loggedIn$.subscribe(
      (data:any) => {
        if(data != null) {          
          this.loggedin = data;          
         
          if(data == false) {
            this.opened = false;
          }
        }
      }
    )

    this._sidebarService.sidebar_status$.subscribe(
      (data:any) => {
        if(data != null) {
          this.opened = data.status;
        }
      }
    )
    
  }  

  toggleSidebar() {
    this._sidebarService.toggleSidebar();
  }
  
}
