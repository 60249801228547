import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  sidebar_status = false;
  sidebar_status$ = new BehaviorSubject<Object>(null);

  constructor() { }

  toggleSidebar() {
    this.sidebar_status = !this.sidebar_status;
    this.sidebar_status$.next({
      status: this.sidebar_status
    });
  }
}
