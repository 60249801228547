import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { Observable, of } from 'rxjs';
import { map, catchError, debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  @Input() internal: boolean;
  @Output() added = new EventEmitter<boolean>();

  mainForm: FormGroup;
  submitted = false;
  form_error = false;

  loading: boolean = true;
  update_user: Boolean;

  /**
   * Autocomplete
   */
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  connected_account: string;
  visible = true;
  selectable = true;
  removable = true;
  filteredUsers: Observable<any[]> = null;
  //connectCtrl = new FormControl();
  

  @ViewChild('userInput', { static: true }) userInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(
    private _dialogRef: MatDialogRef<NewUserComponent>,
    private fb: FormBuilder, 
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  get f() { return this.mainForm.controls; }

  ngOnInit() {

    if(this.data && this.data.id) {

      this._userinfoService.getUser(this.data.id).subscribe((result: any) => {
        
        this.loading = false;

        if(result.success) {

          this.update_user = true;
          this.createForm(result.data);
          
        }
        else {
          this.createForm();

          this._snackBar.open('Customer is not found in the database', '', {
            duration: 2000,
          });
        }
  
      }, err => {

        this.loading = false;

        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error on the server', '', {
            duration: 2000,
          });
        }
      });

    }
    else {
      this.loading = false;
      this.createForm();
    }

    /*
    this.filteredUsers = this.connectCtrl.valueChanges.pipe(
      debounceTime(300),     
      switchMap(value => {
        if (value !== '') {
          // lookup from github
          return this._filter(value);
        } else {
          // if no value is pressent, return null
          return of(null);
        }
      })

    );*/
  }

  createForm(existing_data?) {
    this.mainForm = this.fb.group({
      email: [(existing_data) ? existing_data.email : '', Validators.required],
      password: ['', Validators.required],
      role: [(existing_data && existing_data.role != null) ? existing_data.role + '' : '0', Validators.required]
    });

    if(existing_data) {
      if (this.mainForm.contains('password')) {
        this.mainForm.removeControl('password');

        this.mainForm.addControl('password', new FormControl(''));
      }
    }
  }

  onCancel() {

    if(!this.internal) {
      this._dialogRef.close();
    }
    else {
      this.added.emit(true);
    }

  }

  onSubmit() {
    this.submitted = true;

    this.mainForm.updateValueAndValidity();

    if (this.mainForm.status != "INVALID") {


      let action = null;

      if(this.update_user) {
        action = this._userinfoService.updateUser(this.data.id, this.mainForm.value);
      }
      else {
        action = this._userinfoService.saveNewUserAdmin(this.mainForm.value);
      }


      //this._userinfoService.saveNewUserAdmin(this.mainForm.value)
      
      action.subscribe((result: any) => {   

        if(result.success) {

          let data = this.mainForm.value;
          data['_id'] = result.id;
          data['validated'] = true;
          
          if(!this.internal) {
            this._dialogRef.close(this.mainForm.value);
          }
          else {
            this.added.emit(true);
          }

          if(this.update_user) {
            // snackbar
            this._snackBar.open('User updated', '', {
              duration: 2000,
            });
          }
          else {
            // snackbar
            this._snackBar.open('User added', '', {
              duration: 2000,
            });
          }
        }
  
      }, err => {
        if (err.status != 200) {
          // snackbar
          this._snackBar.open('Error on the server', '', {
            duration: 2000,
          });
        }
      })

    }
    else {
      this.form_error = true;
    }
  }

  private _filter(value: string): Observable<any[]> {

    return this._userinfoService.getFilterUsers(value, 25).pipe(
      // map the item property of the github results as our return object
      map(data => data),
      // catch errors
      catchError(_ => {
        return of(null);
      })
    );

  }

  /*
  selected(event: MatAutocompleteSelectedEvent): void {
       
    this.mainForm.patchValue({
      connected_account: event.option.value.account_id,
    });

    this.connected_account = event.option.value.first_name + " " + event.option.value.last_name;

    this.userInput.nativeElement.value = '';
    this.connectCtrl.setValue(this.connected_account);

  }*/

  /*
  onRoleChange(event) {
    
    if(event.value == 1) {
      /**
       * Manager
       * /

      if (this.mainForm.contains('connected_account')) {
        this.mainForm.removeControl('connected_account');
      }
    }
    else {
      /**
       * Viewer
       * /
      if (!this.mainForm.contains('connected_account')) {
        this.mainForm.addControl('connected_account', new FormControl('', [Validators.required]));
      }

    }
    
  }*/

  /*
  removeConnected() {

    this.mainForm.patchValue({
      connected_account: '',
    });

    this.connected_account = '';

    this.userInput.nativeElement.value = '';
    this.connectCtrl.setValue(this.connected_account);

  }*/

}
