import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource, MatPaginator } from '@angular/material';

import { CustomerService } from 'src/app/services/customer.service';
import { map, startWith, catchError, debounceTime, switchMap, filter, distinctUntilChanged } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';
import { NewCustomerComponent } from '../new-customer/new-customer.component';


@Component({
  selector: 'app-browse-customers',
  templateUrl: './browse-customers.component.html',
  styleUrls: ['./browse-customers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ]
})
export class BrowseCustomersComponent implements OnInit {


  add_customer: boolean;

  displayedColumns: string[] = ['name', 'no_invoice', 'action'];
  customersData: MatTableDataSource<any>;
  resultsLength = 0;
  filter: string;

  newCustomerDialogRef: MatDialogRef<NewCustomerComponent>;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  constructor(
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<BrowseCustomersComponent>,
    private _customerService: CustomerService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {

    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current   
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {

      console.log(text);
      this.getCustomerData(null, null, text);

    });

    this.getCustomerData();

  }

  private getCustomerData(pageIndex?, pageSize?, filter?) {
    this._customerService.getCustomers(pageIndex, pageSize, filter).subscribe((result: any) => {

      if (result.success) {
        this.customersData = new MatTableDataSource(result.data);

        this.customersData.paginator = this.paginator;

        this.resultsLength = result.count;
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
        });
      }
    });
  }

  onPaginateChange(event: any) {

    this.getCustomerData(event.pageIndex, event.pageSize, (this.filter != '') ? this.filter : null);

  }

  selectCustomer(customer:any) {

    this._dialogRef.close(customer);

  }


  addCustomer(input:boolean) {
    this.add_customer = input;
  }  

  onCustomerAdded(saved: boolean) {    
    this.getCustomerData();
    // hide the form
    this.addCustomer(false);
  }

  editCustomer(id) {
    this.newCustomerDialogRef = this._dialog.open(
      NewCustomerComponent,
      {
        width: '450px',
        data: { 
          id: id
        }
      }
    );

    this.newCustomerDialogRef.afterClosed().subscribe(result => {

      if (result) {

        for(let i = 0; i < this.customersData.data.length; i++) {

          if(this.customersData.data[i]._id == id) {

            console.log(result);

            this.customersData.data[i].city = result.city;
            this.customersData.data[i].company_name = result.company_name;
            this.customersData.data[i].country = result.country;
            this.customersData.data[i].street = result.street;
            this.customersData.data[i].contact_name = result.contact_name;
            this.customersData.data[i].contact_email = result.contact_email;
            
          }

        }


      }
      
    });
  }

  deleteCustomer(id) {

    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: { 
          text: '... to delete this customer?' 
        }
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {

      if (result) {

        this._customerService.deleteCustomer(id).subscribe((result: any) => {   

          if(result.success) {
            this._snackBar.open('Customer was deleted successfully', '', {
              duration: 2000,
            });

            this.getCustomerData();

          }
          else {
            this._snackBar.open(result.message, '', {
              duration: 2000,
            });
          }
    
        }, err => {
          if (err.status != 200) {
            this._snackBar.open('Error on the server', '', {
              duration: 2000,
            });
          }
        })

      }
    });

  }

}
