import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatDatepickerModule, MatNativeDateModule, MatSnackBarModule, MatCardModule, MatSelectModule, MatIconModule, MatRippleModule, MatDialogModule, MatTabsModule, MatCheckboxModule, MatSidenavModule, MatProgressSpinnerModule, MatProgressBarModule, MatExpansionModule, MatRadioModule, MatAutocompleteModule, MatChipsModule, MatTooltipModule } from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthguardService } from './services/authguard.service';
import { LicenseService } from './services/license.service';
import { UserinfoService } from './services/userinfo.service';
import { HttpOptionsService } from './services/http-options.service';
import { CustomerService } from './services/customer.service';
import { SidebarService } from './services/sidebar.service';

import { HeaderComponent } from './design/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { AddLicenseComponent } from './pages/license/add-license/add-license.component';
import { BrowseCustomersComponent } from './pages/customers/browse-customers/browse-customers.component';
import { NewCustomerComponent } from './pages/customers/new-customer/new-customer.component';
import { ViewLicenseComponent } from './pages/license/view-license/view-license.component';
import { UsersComponent } from './pages/users/users.component';
import { UserloadService } from './services/userload.service';
import { NewUserComponent } from './pages/users/new-user/new-user.component';
import { ConfirmDialogComponent } from './utils/confirm-dialog/confirm-dialog.component';

export function UserloadProviderFactory(provider: UserloadService) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    LoginComponent,
    LogoutComponent,
    AddLicenseComponent,
    BrowseCustomersComponent,
    NewCustomerComponent,
    ViewLicenseComponent,
    UsersComponent,
    NewUserComponent,
    ConfirmDialogComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatRippleModule,
    MatDialogModule,
    MatTabsModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatProgressSpinnerModule, 
    MatProgressBarModule,
    MatExpansionModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTooltipModule,
  ],
  entryComponents: [
    BrowseCustomersComponent,
    ConfirmDialogComponent,
    NewCustomerComponent,
    NewUserComponent
  ],
  providers: [
    CookieService,
    LicenseService,
    AuthguardService,
    UserinfoService,
    HttpOptionsService,
    CustomerService,
    SidebarService,
    { provide: APP_INITIALIZER, useFactory: UserloadProviderFactory, deps: [UserloadService], multi: true } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
