import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UserinfoService } from 'src/app/services/userinfo.service';
import { MatTableDataSource, MatPaginator, MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { NewUserComponent } from './new-user/new-user.component';
import { ConfirmDialogComponent } from 'src/app/utils/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class UsersComponent implements OnInit {

  displayedColumns: string[] = ['validated', 'email', 'role'];
  usersData: MatTableDataSource<any>;
  resultsLength = 0;
  filter: string;

  loading: boolean = true;

  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  newUserDialogRef: MatDialogRef<NewUserComponent>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _dialog: MatDialog,
    private _userinfoService: UserinfoService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getUsersData();
  }

  private getUsersData(pageIndex?, pageSize?, filter?) {
    this._userinfoService.getAllUsersAdmin(pageIndex, pageSize, filter).subscribe((result: any) => {

      this.loading = false;

      if (result.success) {        

        this.usersData = new MatTableDataSource(result.data);

        this.usersData.paginator = this.paginator;

        this.resultsLength = result.count;
      }

    }, err => {

      this.loading = false;

      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
        });
      }
    });
  }

  addUser() {

    this.newUserDialogRef = this._dialog.open(
      NewUserComponent,
      {
        width: '450px',
      }
    );

    this.newUserDialogRef.afterClosed().subscribe(result => {

      if (result) {

        this.usersData.data.push(result);
        this.usersData = new MatTableDataSource(this.usersData.data);
        this.resultsLength++;        

      }

    });

  }

  editUser(id) {

    this.newUserDialogRef = this._dialog.open(
      NewUserComponent,
      {
        width: '450px',
        data: { 
          id: id
        }
      }
    );

    this.newUserDialogRef.afterClosed().subscribe(result => {

      if (result) {

        for(let i = 0; i < this.usersData.data.length; i++) {

          if(this.usersData.data[i]._id == id) {
            this.usersData.data[i].email = result.email;
            this.usersData.data[i].role = result.role;
          }

        }


      }
      
    });
    
  }

  deleteUser(id) {


    this.confirmDialogRef = this._dialog.open(
      ConfirmDialogComponent,
      {
        width: '350px',
        data: { 
          text: '... that you want to delete this user?' 
        }
      }
    );

    this.confirmDialogRef.afterClosed().subscribe(result => {

      if (result) {

        this._userinfoService.deleteUser(id).subscribe((result: any) => {   

          if(result.success) {
            this._snackBar.open('User was deleted', '', {
              duration: 2000,
            });

            this.getUsersData();

          }
    
        }, err => {
          if (err.status != 200) {
            this._snackBar.open('Error on the server', '', {
              duration: 2000,
            });
          }
        })

      }
    });

  }  

}
