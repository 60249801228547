import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AuthentificationService } from 'src/app/services/authentification.service';
import { HttpOptionsService }      from '../../services/http-options.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mainForm: FormGroup;
  submitted = false;

  returnUrl: string;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _fb: FormBuilder, 
    private _cookieService: CookieService,
    private _httpOptionsService: HttpOptionsService,
    private _authenticationService: AuthentificationService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.createForm();

    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
  }

  createForm() {
    this.mainForm = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]      
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.mainForm.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.mainForm.status != "INVALID") {

      this._authenticationService.login(this.mainForm.value.email, this.mainForm.value.password).subscribe((result: any) => {  
       
        let expiredDate = new Date();
        expiredDate.setDate( expiredDate.getDate() + 30 );

        this._cookieService.set('jwtToken', result.token, expiredDate);
        this._cookieService.set('intLogin', 'true', expiredDate, '/', 'license.intesosolutions.com');

        this._httpOptionsService.refreshHeader();

        this._snackBar.open('Login successfull. Preparing the data ...', '', {
          duration: 2000,
        });
        
        if(this.returnUrl) {
          this._router.navigate([this.returnUrl]);
        }
        else {
          this._router.navigate(['home']);
        }
        
      }, err => {  

        /*
        if(err.error && err.error.not_validate_hint) {
          //this.another_email_validate_link = true;
        }*/
        
        if(err.status != 200) {
          this._snackBar.open('Error on the server. Please try again.', (err.error && err.error.message) ? (err.error.message) : (err.statusText), {
            duration: 2000,
          });
        }
      });

    }
    else {
      this._snackBar.open('Please enter your data', '', {
        duration: 2000,
      });
    }
    
  }

}
