import * as moment from 'moment';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSnackBar } from '@angular/material';
import { LicenseService } from 'src/app/services/license.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = true;
  displayedColumns: string[] = ['company_name', 'date_created', 'limit_license', 'expiry', 'user_id', 'action'];
  licenseData: MatTableDataSource<any>;
  resultsLength = 0;
  filter: string;

  startDate: any;
  endDate: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _router: Router,
    private _licenseService: LicenseService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    const start_date = moment().startOf('year').unix();
    const end_date = moment().endOf('year').unix();

    this.startDate = moment().startOf('year').format('YYYY-MM-DD');
    this.endDate = moment().endOf('year').format('YYYY-MM-DD');

    this.getlicenseData(start_date, end_date, 0, 10);
  }

  private getlicenseData(from, to, pageIndex?, pageSize?, filter?) {
    this._licenseService.getLicenses(from, to, pageIndex, pageSize, filter).subscribe((result: any) => {

      this.loading = false;

      if (result.success) {
        this.licenseData = new MatTableDataSource(result.data);

        this.licenseData.paginator = this.paginator;

        this.resultsLength = result.count;
      }

    }, err => {
      if (err.status != 200) {
        this._snackBar.open('Error on the server', '', {
          duration: 2000,
        });
      }
    });
  }

  viewInvoice(element: any) {

    this._router.navigate(['invoice/' + element._id]);

  }

  repeatInvoice(id) {
    this._router.navigate(['new-invoice/' + id]);
  }

  applyFilter(): void {
    const start_date = moment(this.startDate).unix();
    const end_date = moment(this.endDate).unix();

    this.getlicenseData(start_date, end_date, this.paginator.pageIndex, this.paginator.pageSize, this.filter);
  }

  onPageChange(event: any): void {
    this.getlicenseData(
      moment(this.startDate).unix(),
      moment(this.endDate).unix(),
      event.pageIndex,
      event.pageSize,
      this.filter
    );
  }

}
