
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { CookieService } from 'ngx-cookie-service';
import { HttpOptionsService } from './http-options.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserinfoService } from './userinfo.service';

@Injectable()
export class AuthguardService {

  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router,     
    private cookieService: CookieService,
    private _userinfoService: UserinfoService    
  ) { }


  resolve() {

    if(this.cookieService.get('jwtToken')) {

      this._userinfoService.getUserInfo().subscribe((data: any) => {

        if(data && data.success) {

          let user = data.data;

          if (user.role == 0) {
            // admin          
            this.permissionsService.addPermission('ADMIN');
          }
          else if (user.role == 1) {
            // manager - account owner
            this.permissionsService.addPermission('MANAGER');
          }
          else {
            // employee
            this.permissionsService.addPermission('EMPLOYEE');
          }

        }
        else {
          this.logout();

          return false;
        }

        

      }, err => {         
        if(err.status != 200) {  
          
          this.logout();

          return false;
        }
      })

      this._userinfoService.setLoggedIn(true);
      return true;      
    }
    else {
      this.permissionsService.flushPermissions();

      this._userinfoService.setLoggedIn(false);
      return false;
    }
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  

    if(this.cookieService.get('jwtToken')) {
      this._userinfoService.setLoggedIn(true);
      return true;      
    }
    else {
      this._userinfoService.setLoggedIn(false);
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
   
  }

  logout(redirect?) {     

    this.permissionsService.flushPermissions();

    this.cookieService.deleteAll();    
    this._userinfoService.setLoggedIn(false);

    if(redirect != false) {
      this.router.navigate(['login']);
    }


    
  }

}

