import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { HttpOptionsService } from '../services/http-options.service';

import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class UserinfoService {

  private webUrl = config.api_url; 

  user_info = {};
  
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

  constructor(
    private _http: HttpClient,
    private _httpOptionsService: HttpOptionsService
  ) { }

  getUserInfo() {
    return this._http.get(this.webUrl + '/api/user/info', this._httpOptionsService.getHeader())
  }

  getUser(id) {
    return this._http.get(this.webUrl + '/api/user/' + id, this._httpOptionsService.getHeader())
  }

  updateUserInfo(userInfo) {
    return this._http.put(this.webUrl + '/api/user/info', userInfo, this._httpOptionsService.getHeader())
  }

  getAllUsersAdmin(index?, size?, filter?) {

    let url_query = '';

    if(index != null && size != null) {
      url_query = '?index=' + index + '&size=' + size;

      if(filter) {
        url_query += '&filter=' + filter;
      }
    }
    else {
      if(filter) {
        url_query += '?filter=' + filter;
      }
    }

    return this._http.get(this.webUrl + '/api/user/all' + url_query, this._httpOptionsService.getHeader())
  }

  saveNewUserAdmin(userInfo) {
    return this._http.post(this.webUrl + '/api/user', userInfo, this._httpOptionsService.getHeader())
  }

  deleteUser(id) {
    return this._http.delete(this.webUrl + '/api/user/' + id, this._httpOptionsService.getHeader()) 
  }

  getFilterUsers(search: string, limit?: number) {
    
    if(!limit) {
      limit = 25;
    }

    return this._http.get(this.webUrl + '/api/user/search/' + limit + '/' + search)
  }

  setLoggedIn(value: boolean) {
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  getLoggedIn() {
    return this.loggedIn;
  } 

  updateUser(user_id, userInfo) {
    return this._http.put(this.webUrl + '/api/user/' + user_id, userInfo, this._httpOptionsService.getHeader())    
  }

}
