import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  private webUrl = config.api_url;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }


  login(username: string, password: string) {

    return this.http.post(this.webUrl + '/login', { username: username, password: password });

  }

  forgot(username: string) {
    
    return this.http.post(this.webUrl + '/forgot/pass', { username: username });

  }

  checkForgotId(id: string) {

    return this.http.get(this.webUrl + '/forgot/pass/' + id);

  }

  requestEmailValidation(username: string) {

    return this.http.post(this.webUrl + '/request/validation', { username: username });

  }

}
