import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthguardService } from './services/authguard.service';

import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { HomeComponent } from './pages/home/home.component';
import { AddLicenseComponent } from './pages/license/add-license/add-license.component';
import { ViewLicenseComponent } from './pages/license/view-license/view-license.component';
import { UsersComponent } from './pages/users/users.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'add/license', component: AddLicenseComponent, canActivate: [AuthguardService] },
  { path: 'view/license/:id', component: ViewLicenseComponent, canActivate: [AuthguardService] },
  { path: 'users', component: UsersComponent, canActivate: [AuthguardService] },
  { path: 'logout', component: LogoutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, resolve: [AuthguardService], canActivate: [AuthguardService] },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
